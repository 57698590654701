import React from "react"
import sectionStyles from "./section.module.css"

export default function Section({
  children,
  color,
  bgColor,
  statement,
  title,
}) {
  return (
    <div
      className={sectionStyles.section}
      style={{ background: bgColor || "#fff", color: color || "inherit" }}
    >
      <h2 className={sectionStyles.title}>{title}</h2>
      <div className={sectionStyles.statement}>{statement}</div>
      {children}
    </div>
  )
}
